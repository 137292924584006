import React, { Component } from "react";
import ReactDOM from "react-dom";

import AuthStore from "../../Stores/authStore";
import HTML5Backend from "react-dnd-html5-backend";
import { DragDropContext } from "react-dnd";

import BigCalendarCSS from "react-big-calendar/lib/css/react-big-calendar.css";
import withDragAndDrop from "react-big-calendar/lib/addons/dragAndDrop";

import BigCalendar from "react-big-calendar";
import moment from "moment";
import "react-big-calendar/lib/css/react-big-calendar.css";

import OperationPopup1 from "./OperationPopup";
import OperationModal from "../OperationModal";

import OperationPopup from "../OperationPopup";

import Popup from "reactjs-popup";

// moment.locale("ko", {
//   week: { dow: 1 }
// });
const localizer = BigCalendar.momentLocalizer(moment);
// const DragAndDropCalendar = withDragAndDrop(BigCalendar);

class Dnd extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedEvent: ""
    };

    this.resetSelectedEvent = this.resetSelectedEvent.bind(this);
    this.handleEventSelect = this.handleEventSelect.bind(this);
  }

  handleEventSelect(event) {
    this.setState({ selectedEvent: event });
  }

  resetSelectedEvent() {
    this.setState({ selectedEvent: "" });
  }
  getOperationItemView() {
    if (this.state.selectedEvent) {
      return (
        <OperationPopup1
          room={this.props.room}
          operation={this.state.selectedEvent}
          deselect={this.resetSelectedEvent}
        />
      );
    }
  }

  render() {
    const show =
      this.state.selectedEvent === "" ? "col-lg-12" : "col-lg-10 col-sm-12";
    const showButton = this.selectedEvent === "" ? "col-lg-12" : "col-lg-2";
    return (
      <div className="row">
        <div className={show}>
          <BigCalendar
            style={{ height: 700 }}
            selectable
            localizer={localizer}
            defaultView="week"
            views={["week", "day"]}
            defaultDate={new Date()}
            events={this.props.events}
            onSelectEvent={this.handleEventSelect}
            min={new Date(2017, 10, 0, 6, 0, 0)}
            max={new Date(2017, 10, 0, 23, 0, 0)}
          />
        </div>
        <div className={showButton}>
          <OperationModal
            room={this.props.room}
            event={this.state.selectedEvent}
          />
          {this.getOperationItemView()}
        </div>
      </div>
    );
  }
}

export default Dnd;
