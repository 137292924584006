import React, { Component } from "react";
import { observer } from "mobx-react";
import axios from "axios";

import AuthStore from "../../Stores/authStore";
import UserStore from "../../Stores/userStore";
class DoctorDetail extends Component {
  constructor(props) {
    super(props);
    this.state = {
      first_name: "",
      last_name: "",
      email: "",
      is_staff: false
    };

    this.getDoctor();
    this.handleChange = this.handleChange.bind(this);
    // this.handleSubmit = this.handleSubmit.bind(this);
  }

  componentDidMount() {}

  handleChange(event) {
    this.setState({ [event.target.name]: event.target.value });
  }
  getDoctor() {
    if (AuthStore.user) {
      AuthStore.getDoctorProfile(AuthStore.user.user_id);
    }
  }

  render() {
    const doctor = AuthStore.doctor;

    if (doctor) {
      return (
        <div>
          {doctor.user.username} unit: {doctor.unit}
        </div>
      );
    } else {
      return <div />;
    }
  }
}

export default observer(DoctorDetail);
