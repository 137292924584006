import { decorate, observable, action, computed } from "mobx";
import axios from "axios";

const instance = axios.create({
  // baseURL: "http://127.0.0.1:8000/"
  baseURL: "https://otapi.traumasurgerykw.com/"
});

class RoomStore {
  constructor() {
    this.rooms = [];
    this.room = null;
    this.operations = [];
    this.units = [];
    this.loading = true;
    this.loadingRooms = true;
    this.loadingOperations = true;
    this.error = "";

    // this.fetchRooms();
  }

  fetchRooms() {
    instance
      .get("room/list/")
      .then(res => res.data)
      .then(rooms => {
        this.rooms = rooms;
        this.operations = rooms.operation;
        this.loadingRooms = false;
      })
      .catch(error => {
        this.error = error;
      });
  }

  addRoom(userInput) {
    instance
      .post("room/create/", userInput)
      .then(res => res.data)
      .then(() => this.fetchRooms())
      .catch(err => console.error(err));
  }

  fetchRoomOperations(roomID) {
    return instance
      .get(`room/detail/${roomID}/`)
      .then(res => res.data)
      .then(rooms => {
        this.operations = rooms.operation;
        this.loadingOperations = false;
      })
      .catch(error => console.error(error));
  }
  getRoom(roomID) {
    instance
      .get(`room/detail/${roomID}`, roomID)
      .then(res => res.data)
      .then(room => {
        this.room = room;
        this.operations = room.operation;
        this.loading = false;
        this.units = room.unit;
      })
      .catch(error => {
        this.error = error;
      });
  }

  addOperation(roomID, userInput) {
    instance
      .post("operation/create/", userInput)
      .then(res => res.data)
      .then(() => {
        this.getRoom(roomID);
      })
      .catch(err => console.error(err));
  }

  updateOperation(roomID, operationID, userInput) {
    const opIndex = this.operations.findIndex(op => op.id === operationID);
    instance
      .put("operation/update/" + operationID + "/", userInput)
      .then(res => res.data)
      .then(() => this.getRoom(roomID))
      .catch(err => console.error(err));
  }

  deleteOperation(roomID, operationID) {
    this.operations = this.operations.filter(
      operation => operation.id !== operationID
    );
    instance
      .delete("operation/delete/" + operationID + "/")
      .catch(err => console.error(err));
  }

  get roomOperations() {
    let events = [];
    if (!this.loading) {
      if (this.operations) {
        this.operations.forEach(operation => {
          let start = new Date(
            operation.admission_date + " " + operation.start_time
          );
          let end = new Date(
            operation.admission_date + " " + operation.start_time
          );
          end.setHours(end.getHours() + operation.duration);
          events.push({
            id: operation.id,
            title:
              operation.patient.first_name +
              " " +
              operation.patient.last_name +
              "- " +
              operation.procedure.name,
            start: start,
            end: end,
            patient: operation.patient,
            doctor: operation.doctor,
            diagnosis: operation.diagnosis,
            duration: operation.duration,
            start_time: operation.start_time,
            admission_date: operation.admission_date,
            unit: operation.unit,
            procedure: operation.procedure,
            room: operation.room,
            taskchecklist: operation.taskchecklist
          });
        });
      }
      return events;
    }
  }
}

decorate(RoomStore, {
  room: observable,
  rooms: observable,
  operations: observable,
  units: observable,
  loading: observable,
  error: observable,
  roomOperations: computed
});

export default new RoomStore();
