import React, { Component } from "react";

import TaskItem from "./TaskItem";

class TaskList extends Component {
  render() {
    const { tasks } = this.props;

    let TaskList;
    if (tasks) {
      TaskList = tasks.map(task => <TaskItem task={task} key={task.id} />);
    }
    return <div>{TaskList}</div>;
  }
}

export default TaskList;
