import React, { Component } from "react";
import { Link } from "react-router-dom";
class RoomItem extends Component {
  render() {
    const { room } = this.props;
    return (
      <div className="col-lg-4 col-md-6 col-12">
        <div className="card">
          <Link to={`/room/${room.id}/`}>
            <div className="card-body">
              <h1 className="card-title text-center">{room.number}</h1>
              <p className="card-text">{room.status}</p>
            </div>
          </Link>
        </div>
      </div>
    );
  }
}

export default RoomItem;
