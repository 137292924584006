import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import { observer } from "mobx-react";
import { Navbar, Nav, NavDropdown } from "react-bootstrap";
import AuthStore from "../../Stores/authStore";

class Header extends Component {
  getHeaderView() {
    if (AuthStore.user) {
      return (
        <Navbar bg="light">
          <Navbar.Brand href="#home">OT</Navbar.Brand>
          <Navbar.Toggle aria-controls="basic-navbar-nav" />
          <Navbar.Collapse id="basic-navbar-nav">
            <Nav className="ml-auto">
              <Nav.Link>Hello, {AuthStore.user.username}</Nav.Link>
              <Nav.Link onClick={() => AuthStore.logout(this.props.history)}>
                Logout
              </Nav.Link>
            </Nav>
          </Navbar.Collapse>
        </Navbar>
      );
    } else {
      return (
        <Navbar bg="light">
          <Navbar.Brand href="#home">OT</Navbar.Brand>
          <Navbar.Toggle aria-controls="basic-navbar-nav" />
          <Navbar.Collapse id="basic-navbar-nav">
            <Nav className="ml-auto">
              <Nav.Link href="/signup/">Register</Nav.Link>
              <Nav.Link href="/login/">Login</Nav.Link>
            </Nav>
          </Navbar.Collapse>
        </Navbar>
      );
    }
  }
  render() {
    return this.getHeaderView();
  }
}

export default withRouter(observer(Header));
