import React, { Component } from "react";
import { observer } from "mobx-react";
import OperationForm from "../Forms/OperationForm";
import Modal from "react-responsive-modal";
import { Button } from "reactstrap";
import AuthStore from "../Stores/authStore";
import roomStore from "../Stores/roomStore";
class OperationModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      open: false,
      loading: false
    };

    this.onOpenModal = this.onOpenModal.bind(this);
    this.onCloseModal = this.onCloseModal.bind(this);
  }

  componentDidMount() {
    this.setState({ loading: this.checkDoctorPermissions() });
  }

  onOpenModal() {
    this.setState({ open: true });
  }

  checkDoctorPermissions() {
    let doctor = AuthStore.doctor;

    let found = false;
    if (doctor) {
      if (doctor.user.is_staff) {
        roomStore.room.unit.forEach(unit => {
          if (unit.id === doctor.unit) {
            found = true;
          }
        });
      }
      return found;
    }
  }

  onCloseModal() {
    this.setState({ open: false });
  }

  render() {
    const { open } = this.state;
    const { room, event } = this.props;

    const show = event === "" ? "col-12" : "col-2";

    if (!AuthStore.doctor) {
      return <div />;
    }
    if (this.checkDoctorPermissions()) {
      return (
        <div className={show}>
          <div
            className="text-center"
            style={{ marginTop: 10, marginBottom: 10 }}
          >
            <div>
              <button
                style={{
                  width: 80,
                  height: 80,
                  borderRadius: 40
                }}
                className="btn btn-outline-dark"
                onClick={this.onOpenModal}
              >
                <div style={{ fontSize: 30 }}>+</div>
              </button>
            </div>
          </div>
          <div>
            <Modal open={open} onClose={this.onCloseModal} center>
              <OperationForm room={room} closeModal={this.onCloseModal} />
            </Modal>
          </div>
        </div>
      );
    } else {
      return <div>Not authorized to schedule operations for this room</div>;
    }
  }
}

export default observer(OperationModal);
