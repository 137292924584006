import { decorate, observable, computed } from "mobx";
import axios from "axios";

const instance = axios.create({
  // baseURL: "http://127.0.0.1:8000/"
  baseURL: "https://otapi.traumasurgerykw.com/"
});

class UnitStore {
  constructor() {
    this.units = [];
    this.operation = null;
    this.operationLoading = true;
    this.loading = true;

    this.fetchUnits();
  }

  fetchUnits() {
    instance
      .get("unit/list/")
      .then(res => res.data)
      .then(units => (this.units = units))
      .then(() => (this.loading = false))
      .catch(err => console.log(err));
  }

  fetchAnOperation(operationID) {
    instance
      .get(`operation/${operationID}/detail/`)
      .then(res => res.data)
      .then(operation => {
        this.operation = operation;
      })
      .then(() => (this.operationLoading = false))
      .catch(err => console.log(err));
  }

  addUnit(userInput) {
    instance
      .post("unit/create/", userInput)
      .then(res => res.data)
      .then(() => this.fetchUnits())
      .catch(err => console.error(err));
  }

  updateUnits(userInput, unitID) {
    instance
      .put(`unit/${unitID}/update/`, userInput)
      .then(res => res.data)
      .catch(err => console.error(err));
  }

  updateTask(userInput, taskID) {
    instance
      .put(`task/${taskID}/update/`, userInput)
      .then(res => res.data)
      .catch(err => console.error(err));
  }
}

decorate(UnitStore, {
  units: observable,
  operation: observable,
  operationLoading: observable,
  loading: observable
});

export default new UnitStore();
