import React, { Component } from "react";

class OperationItem extends Component {
  render() {
    const { operation } = this.props;

    return (
      <div className="col-lg-4 col-md-6 col-12">
        <div className="card">
          <div className="card-body">
            <h1 className="card-title text-center">{operation.number}</h1>
            <p className="card-text">
              Doctor:{operation.doctor.user.first_name}{" "}
              {operation.doctor.user.last_name}{" "}
            </p>
            <p className="card-text">
              Admission Date:{operation.admission_date}
              Time: {operation.start_time}
            </p>
            <p className="card-text">Patient:{operation.patient.first_name}</p>
            <p className="card-text">Diagnosis:{operation.diagnosis}</p>
          </div>
        </div>
      </div>
    );
  }
}

export default OperationItem;
