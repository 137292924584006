import React, { Component } from "react";

import SignupForm from "../../Forms/SignupForm";
import AuthStore from "../../Stores/authStore";

class Signup extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    return (
      <div>
        <SignupForm history={this.props.history} />
      </div>
    );
  }
}
export default Signup;
