import React, { Component } from "react";
import { observer } from "mobx-react";
import roomStore from "../Stores/roomStore";
import PatientForm from "./PatientForm";
import { Switch, Route, Redirect, withRouter } from "react-router-dom";
import DatePicker from "react-datepicker";
import Alert from "react-bootstrap/Alert";
import Select from "react-select";
import MultiSelect from "@kenshooui/react-multi-select";
import MultiSelectReact from "multi-select-react";
import TimeField from "react-simple-timefield";
import PatientStore from "../Stores/patientStore";
import AuthStore from "../Stores/authStore";
import UserStore from "../Stores/userStore";

import "@kenshooui/react-multi-select/dist/style.css";
import "react-datepicker/dist/react-datepicker.css";
class OperationForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      room: this.props.room.id,
      procedure: "",
      admission_date: new Date(),
      start_time: "",
      duration: "",
      diagnosis: "",
      first_name: "",
      last_name: "",
      civil_id: "",
      unit: "",
      doctor: [],
      doctors: [],
      errors: []
    };

    // PatientStore.fetchPatients();

    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleDocChange = this.handleDocChange.bind(this);
    this.selectedBadgeClicked = this.selectedBadgeClicked.bind(this);
    this.handleDateChange = this.handleDateChange.bind(this);
    this.handleTimeChange = this.handleTimeChange.bind(this);
  }

  componentDidMount() {
    this.handleDateChange(this.state.admission_date);
    this.checkOperation();
  }

  checkOperation() {
    if (this.props.operation) {
      this.handleDocChange(this.props.operation.doctor);
      this.setState({
        procedure: this.props.operation.procedure,
        admission_date: this.props.operation.admission_date,
        start_time: this.props.operation.start_time,
        duration: this.props.operation.duration,
        diagnosis: this.props.operation.diagnosis,
        first_name: this.props.operation.patient.first_name,
        last_name: this.props.operation.patient.last_name,
        civil_id: this.props.operation.patient.civil_id,
        unit: this.props.operation.unit,
        doctors: this.props.operation.doctor
      });
    }
  }

  handleChange(event) {
    this.setState({ [event.target.name]: event.target.value });
  }

  validate(
    admission_date,
    start_time,
    duration,
    first_name,
    last_name,
    civil_id,
    diagnosis,
    unit,
    doctor,
    procedure
  ) {
    const errors = [];

    if (procedure.length === 0) {
      errors.push("Please choose a procedure name");
    }
    if (admission_date.length === 0) {
      errors.push("Please choose a date");
    }
    if (start_time.length === 0) {
      errors.push("Please choose a start time");
    }
    if (duration.length === 0 || !/^\d+$/.test(duration)) {
      errors.push("Please choose a duration");
    }
    if (first_name.length === 0) {
      errors.push("Please enter patients first name");
    }
    if (last_name.length === 0) {
      errors.push("Please enter patients last name");
    }
    if (civil_id.length !== 12 || !/^\d+$/.test(civil_id)) {
      errors.push("Please enter a valid Civil ID number");
    }
    if (diagnosis.length === 0) {
      errors.push("Please enter patients diagnosis");
    }
    if (unit.length === 0) {
      errors.push("Please choose a unit");
    }
    if (doctor.length === 0) {
      errors.push("Please select the operating doctors");
    }

    return errors;
  }

  handleDateChange(date) {
    let day = (date.getDate() < 10 ? "0" : "") + date.getDate();
    let month = (date.getMonth() + 1 < 10 ? "0" : "") + (date.getMonth() + 1);
    let year = date.getFullYear();
    let formattedDate = year + "-" + month + "-" + day;
    this.setState({ admission_date: formattedDate });
  }

  handleTimeChange(time) {
    this.setState({ start_time: time + ":00" });
  }

  handleSubmit = async event => {
    event.preventDefault();
    const {
      doctor,
      admission_date,
      start_time,
      duration,
      diagnosis,
      first_name,
      last_name,
      civil_id,
      unit,
      procedure
    } = this.state;
    const errors = this.validate(
      admission_date,
      start_time,
      duration,
      first_name,
      last_name,
      civil_id,
      diagnosis,
      unit,
      doctor,
      procedure
    );
    if (errors.length > 0) {
      this.setState({ errors });
      return;
    }
    if (this.props.operation) {
      const operation = this.props.operation;
      await roomStore.updateOperation(operation.room, operation.id, this.state);
    } else {
      await roomStore.addOperation(this.state.room, this.state);
      roomStore.getRoom(this.state.room);
    }
    this.props.closeModal();
    if (this.props.reset) {
      this.props.reset();
    }
  };

  getUnits() {
    const { room } = this.props;
    let unitList;
    unitList = room.unit.map(unit => (
      <option key={unit.id} value={unit.id}>
        {unit.name}
      </option>
    ));

    return unitList;
  }

  selectedBadgeClicked(doctor) {
    this.setState({ doctor });
  }
  handleDocChange(optionsList) {
    let docId = [];
    optionsList.forEach(option => docId.push(option.id));
    this.setState({ doctor: docId });
  }
  selectedBadgeClicked(optionsList) {
    this.setState({ doctor: optionsList });
  }

  getDoctors(doctorlist) {
    const doctors = doctorlist;
    let doctorList = [];
    doctors.forEach(doctor =>
      doctorList.push({
        id: doctor.id,
        label: doctor.user.first_name + " " + doctor.user.last_name
      })
    );

    return doctorList;
  }

  render() {
    const {
      room,
      doctor,
      admission_date,
      start_time,
      duration,
      diagnosis,
      first_name,
      last_name,
      civil_id,
      errors,
      procedure
    } = this.state;
    this.getUnits();
    const selectedOptionsStyles = {
      color: "#3c763d",
      backgroundColor: "#dff0d8"
    };
    const optionsListStyles = {
      backgroundColor: "#dff0d8",
      color: "#3c763d"
    };
    const rooom = this.props.room;
    const doctorOptions = this.getDoctors(UserStore.doctors);

    return (
      <div>
        <div className="col-12">
          {errors.length > 0 ? (
            <Alert variant="danger">{errors[0]}</Alert>
          ) : null}
          <form style={{ width: 600 }} onSubmit={this.handleSubmit}>
            <div className="form-group">
              <label htmlFor="procedure">Procedure</label>
              <input
                type="text"
                className="form-control"
                id="procedure"
                name="procedure"
                value={procedure}
                placeholder="Enter Procedure"
                onChange={this.handleChange}
              />
            </div>
            <div className="form-group">
              <label htmlFor="admission_date">Admission Date</label>
              <div>
                <DatePicker
                  style={{ width: "100%" }}
                  minDate={new Date()}
                  className="form-control"
                  id="admission_date"
                  name="admission_date"
                  selected={new Date(this.state.admission_date)}
                  onChange={this.handleDateChange}
                />
              </div>
            </div>
            <div className="form-group">
              <label htmlFor="start_time">Time</label>
              <div>
                <TimeField
                  style={{
                    position: "relative",
                    width: "100%",
                    height: 40,
                    borderRadius: "5px",
                    border: "1px solid rgb(199,206,213)"
                  }}
                  value={start_time}
                  name="start_time"
                  id="start_time"
                  onChange={this.handleTimeChange}
                />
              </div>
            </div>

            <div className="form-group">
              <label htmlFor="duration">Duration</label>
              <input
                type="text"
                className="form-control"
                id="duration"
                name="duration"
                value={duration}
                placeholder="Enter Duration"
                onChange={this.handleChange}
              />
            </div>
            <div className="form-group">
              <label htmlFor="first_name">First Name</label>
              <input
                type="text"
                className="form-control"
                id="room"
                name="first_name"
                value={first_name}
                placeholder="Enter First Name"
                onChange={this.handleChange}
              />
            </div>

            <div className="form-group">
              <label htmlFor="last_name">Last Name</label>
              <input
                type="text"
                className="form-control"
                id="last_name"
                name="last_name"
                value={last_name}
                placeholder="Enter Last Name"
                onChange={this.handleChange}
              />
            </div>

            <div className="form-group">
              <label htmlFor="civil_id">Civil ID</label>
              <input
                type="text"
                className="form-control"
                id="civil_id"
                name="civil_id"
                value={civil_id}
                placeholder="Enter Civil ID Number"
                onChange={this.handleChange}
              />
            </div>

            <div className="form-group">
              <label htmlFor="diagnosis">Diagonsis</label>
              <input
                type="text"
                className="form-control"
                id="diagnosis"
                name="diagnosis"
                value={diagnosis}
                placeholder="Enter Diagnosis"
                onChange={this.handleChange}
              />
            </div>
            <div className="form-group">
              <label htmlFor="unit">Unit</label>
              <div>
                <select
                  style={{
                    backgroundColor: "transparent",
                    position: "relative",
                    width: "100%",
                    height: 35,
                    marginBottom: 10
                  }}
                  value={this.state.unit}
                  onChange={this.handleChange}
                  name="unit"
                  id="unit"
                >
                  <option />
                  {this.getUnits()}
                </select>
              </div>
              <div className="form-group">
                <label htmlFor="doctor"> Doctor</label>
                <MultiSelect
                  items={doctorOptions}
                  selectedItems={this.state.doctors}
                  onChange={this.handleDocChange}
                  showSelectedItems={false}
                />
              </div>
            </div>
            <div className="text-center">
              <input
                className="btn btn-dark btn-block"
                type="submit"
                value="Submit"
              />
            </div>
          </form>
        </div>
      </div>
    );
  }
}

export default observer(OperationForm);
