import { decorate, observable } from "mobx";
import axios from "axios";
import jwt_decode from "jwt-decode";

const instance = axios.create({
  // baseURL: "http://127.0.0.1:8000/"
  baseURL: "https://otapi.traumasurgerykw.com/"
});

class AuthStore {
  constructor() {
    this.user = null;
    this.doctor = null;
    this.doctorLoading = false;
    this.permit = false;
    this.errors = null;
    this.checkForToken();

    if (this.user) {
      this.getDoctorProfile(this.user.user_id);
    }
  }

  setUser(token) {
    if (token) {
      localStorage.setItem("token", token);
      axios.defaults.headers.common.Authorization = `jwt ${token}`;
      this.user = jwt_decode(token);
    } else {
      localStorage.removeItem("token");
      delete axios.defaults.headers.common.Authorization;
      this.user = null;
      this.doctor = null;
    }
  }

  checkForToken() {
    const token = localStorage.getItem("token");

    if (token) {
      const user = jwt_decode(token);
      if (user.exp > Date.now() / 1000) {
        this.setUser(token);
      } else {
        this.setUser();
      }
    }
  }

  authenticate(userData, type, history) {
    instance
      .post(`/${type}/`, userData)
      .then(res => res.data)
      .then(user => this.setUser(user.token))
      .then(() => {
        if (type === "signin") {
          this.getDoctorProfile(this.user.user_id);
        }
      })
      .then(() => {
        if (type === "signin") {
          history.push("/multi/room/");
        } else {
          history.push("/login/");
        }
        this.errors = null;
      })
      .catch(err => {
        this.errors = err.response.data;
      });
  }

  loginUser(userData, history) {
    this.authenticate(userData, "signin", history);
  }

  signupUser(userData, history) {
    this.authenticate(userData, "signup", history);
  }

  getDoctorProfile(userID) {
    instance
      .get("/doctor/detail/" + userID + "/")
      .then(res => res.data)
      .then(doctor => {
        this.doctor = doctor;
        this.doctorLoading = false;
      })
      .catch(err => {
        return err.response;
      });
  }

  logout(history) {
    this.setUser();
    history.push("/login/");
  }
}

decorate(AuthStore, {
  user: observable,
  doctor: observable,
  errors: observable
});

export default new AuthStore();
