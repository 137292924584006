import React, { Component } from "react";
import axios from "axios";
import TaskList from "./TaskList";

import DatePicker from "react-datepicker";

const instance = axios.create({
  // baseURL: "http://127.0.0.1:8000/"
  baseURL: "https://otapi.traumasurgerykw.com/"
});

class Tasks extends Component {
  constructor(props) {
    super(props);
    this.state = {
      tasks: [],
      tasks2: [],
      selectedDate: new Date()
    };
  }
  componentDidMount = async () => {
    await this.handleDateChange(this.state.selectedDate);
    this.fetchTasks(this.state.selectedDate);
    this.fetchTasksAgain(this.state.selectedDate);
  };

  fetchTasks(date) {
    instance
      .get("tasks/", {
        params: { date: date }
      })
      .then(res => res.data)
      .then(tasks => {
        this.setState({ tasks: tasks });
      })
      .catch(err => console.log(err));
  }

  fetchTasksAgain(date) {
    instance
      .get("tasks/tasks/", {
        params: { date: date }
      })
      .then(res => res.data)
      .then(tasks => {
        this.setState({ tasks2: tasks });
      })
      .catch(err => console.log(err));
  }

  handleDateChange = date => {
    let day = (date.getDate() < 10 ? "0" : "") + date.getDate();
    let month = (date.getMonth() + 1 < 10 ? "0" : "") + (date.getMonth() + 1);
    let year = date.getFullYear();
    let formattedDate = year + "-" + month + "-" + day;

    this.setState({ selectedDate: formattedDate });
  };

  render() {
    const { tasks } = this.state;
    let OperationList = [];
    let roomList = {};
    if (tasks) {
      tasks.forEach(task => {
        if (roomList[task.room.id] !== undefined) {
          roomList[task.room.id].push(task);
        } else {
          roomList[task.room.id] = [task];
        }
      });

      for (var room in roomList) {
        if (roomList[room][0].taskchecklist.length > 0) {
          OperationList.push(
            <div>
              <div
                style={{
                  padding: 20,
                  marginBottom: 5,
                  marginTop: 5,
                  backgroundColor: "#DCDCDC"
                }}
              >
                <strong>{roomList[room][0].room.number}</strong>
              </div>
              <div className="card-deck">
                {roomList[room].map((task, index) => {
                  if (task.taskchecklist.length > 0) {
                    return (
                      <div className="col-lg-5 card">
                        <div className="card-body">
                          <h3>{task.procedure.name}</h3>
                          <h5>Time: {task.start_time}</h5>
                          <h6>
                            Patient: {task.patient.first_name}{" "}
                            {task.patient.last_name}
                          </h6>
                          <br />
                          <h6> Task Checklist </h6>
                          <TaskList key={task.id} tasks={task.taskchecklist} />
                        </div>
                      </div>
                    );
                  }
                })}
              </div>
            </div>
          );
        }
      }
    }
    return (
      <div>
        <DatePicker
          selected={new Date(this.state.selectedDate)}
          name="selected_date"
          id="selected_date"
          onChange={this.handleDateChange}
        />
        <button onClick={() => this.fetchTasks(this.state.selectedDate)}>
          Search
        </button>
        {OperationList}
      </div>
    );
  }
}

export default Tasks;
