import { decorate, observable } from "mobx";
import axios from "axios";

const instance = axios.create({
  // baseURL: "http://127.0.0.1:8000/"
  baseURL: "https://otapi.traumasurgerykw.com/"
});

class PatientStore {
  constructor() {
    this.patients = null;
    this.loading = true;
  }

  fetchPatients() {
    instance
      .get("patient/list/")
      .then(res => res.data)
      .then(patients => (this.patients = patients))
      .then(() => (this.loading = false))
      .catch(err => console.log(err));
  }

  addPatient(userInput) {
    instance
      .post("patient/create/", userInput)
      .then(res => res.data)
      .then(this.fetchPatients())
      .catch(err => console.error(err));
  }

  updatePatient(userInput, patientID) {
    instance
      .put(`patient/${patientID}/update/`, userInput)
      .then(res => res.data)
      .catch(err => console.error(err));
  }

  getPatientById(civilid) {
    return this.patients.find(patient => +patient.civil_id === +civilid);
  }
}

decorate(PatientStore, { patients: observable, loading: observable });
export default new PatientStore();
