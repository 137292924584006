import React, { Component } from "react";

import PatientStore from "../Stores/patientStore";
class PatientForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      first_name: "",
      last_name: "",
      civil_id: ""
    };

    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  handleChange(event) {
    this.setState({ [event.target.name]: event.target.value });
  }

  handleSubmit(event) {
    event.preventDefault();
    PatientStore.addPatient(this.state);
    this.props.closeModal();
  }

  render() {
    const { first_name, last_name, civil_id } = this.state;

    return (
      <div className="col-12 mx-auto">
        <div className="card">
          <div className="card-body">
            <form onSubmit={this.handleSubmit}>
              <div className="form-group">
                <label htmlFor="first_name">First Name</label>
                <input
                  type="text"
                  className="form-control"
                  id="room"
                  name="first_name"
                  value={first_name}
                  placeholder="Enter First Name"
                  onChange={this.handleChange}
                />
              </div>

              <div className="form-group">
                <label htmlFor="last_name">Last Name</label>
                <input
                  type="text"
                  className="form-control"
                  id="last_name"
                  name="last_name"
                  value={last_name}
                  placeholder="Enter Last Name"
                  onChange={this.handleChange}
                />
              </div>

              <div className="form-group">
                <label htmlFor="civil_id">Civil ID</label>
                <input
                  type="text"
                  className="form-control"
                  id="civil_id"
                  name="civil_id"
                  value={civil_id}
                  placeholder="Enter Civil ID Number"
                  onChange={this.handleChange}
                />
              </div>
              <input type="submit" value="Submit" />
            </form>
          </div>
        </div>
      </div>
    );
  }
}

export default PatientForm;
