import React, { Component } from "react";
import { Link } from "react-router-dom";
import { observer } from "mobx-react";
import Table from "react-bootstrap/Table";
import UserStore from "../../Stores/userStore";
import UnitStore from "../../Stores/unitStore";
class DoctorItem extends Component {
  constructor(props) {
    super(props);
    this.state = {
      user: null,
      is_staff: false,
      unit: ""
    };
    this.handleChange = this.handleChange.bind(this);
  }

  componentDidMount() {
    this.setState({
      user: this.props.doctor.user.id,
      is_staff: this.props.doctor.user.is_staff,
      unit: this.props.doctor.unit
    });
  }

  getUnitDropdown() {
    const units = UnitStore.units;
    let UnitList;
    UnitList = units.map(unit => (
      <option key={unit.id} value={unit.id}>
        {unit.name}
      </option>
    ));

    return UnitList;
  }

  updateDoctor(doctor) {}

  handleChange(event) {
    this.setState({ [event.target.name]: event.target.value });
  }

  render() {
    const { doctor } = this.props;
    return (
      <tr>
        <td>{doctor.user.first_name}</td>
        <td>{doctor.user.last_name}</td>
        <td>
          <select
            value={this.state.unit}
            name="unit"
            id="unit"
            onChange={this.handleChange}
          >
            {this.getUnitDropdown()}
          </select>
        </td>
        <td>
          {" "}
          <input
            type="checkbox"
            className="form-control"
            id="is_staff"
            name="is_staff"
            checked={this.state.is_staff}
            placeholder="Has Access?"
            onChange={() => {
              this.setState({ is_staff: !this.state.is_staff });
            }}
          />
        </td>
        <td>
          <button
            className="btn btn-dark"
            onClick={() => {
              UserStore.giveAccess(doctor.user.id, this.state);
              UserStore.fetchUsers();
              this.props.fetch();
            }}
          >
            Update
          </button>
        </td>
      </tr>
    );
  }
}

export default DoctorItem;
