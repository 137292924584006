import React, { Component } from "react";
import { Switch, Route, Redirect, withRouter } from "react-router-dom";
import "./App.css";

import AuthStore from "./Stores/authStore";

//Components
import Sidebar from "./Components/Sidebar";
import Header from "./Components/Header";
import Login from "./Components/Login";
import Signup from "./Components/Signup";
import RoomList from "./Components/RoomList";
import RoomDetail from "./Components/RoomDetail";
import UnitList from "./Components/UnitList";
import SideNavbar from "./Components/SideNav";
import OperationForm from "./Forms/OperationForm";
import DoctorList from "./Components/DoctorList";
import DoctorDetail from "./Components/DoctorDetail";
import MultiRoomCalendar from "./Components/MultiRoomCalendar";
import Tasks from "./Components/TaskList";
import UserList from "./Components/UserList";

class App extends Component {
  getView() {
    return (
      <Switch>
        <Redirect exact from="/" to="/login/" />
        <Route path="/multi/room/" component={MultiRoomCalendar} />
        <Route path="/login/" component={Login} />
        <Route path="/signup/" component={Signup} />
        <Route path="/room/list/" component={RoomList} />
        <Route path="/room/:roomID/" component={RoomDetail} />
        <Route path="/operations/form/" component={OperationForm} />
        <Route path="/unit/list/" component={UnitList} />
        <Route path="/doctor/list/" component={DoctorList} />
        <Route path="/doctor/detail/" component={DoctorDetail} />
        <Route path="/tasks/" component={Tasks} />
        <Route path="/user/list/" component={UserList} />
      </Switch>
    );
  }

  render() {
    return (
      <div>
        <SideNavbar />
        <div className="col-12">{this.getView()}</div>
      </div>
    );
  }
}

export default withRouter(App);
