import React, { Component } from "react";
import { observer } from "mobx-react";

import UserStore from "../../Stores/userStore";
import DoctorItem from "./DoctorItem";
import Table from "react-bootstrap/Table";
class DoctorList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      id: "",
      fetch: false
    };
    // this.getDoctors();
  }

  componentDidMount() {
    UserStore.fetchUsers();
    this.getDoctors();
  }

  getDoctors() {
    this.setState({ fetch: !fetch });
  }

  render() {
    // UserStore.fetchUsers();
    const doctors = UserStore.doctors;
    if (UserStore.loading) {
      return <div />;
    } else {
      let DoctorList;
      if (doctors) {
        DoctorList = doctors.map(doctor => (
          <DoctorItem
            doctor={doctor}
            key={doctor.id}
            fetch={() => this.getDoctors()}
          />
        ));
      }

      return (
        <div>
          {" "}
          <Table>
            <thead>
              <tr>
                <th>First Name</th>
                <th>Last Name</th>
                <th>Unit</th>
                <th>Registrar? </th>
                <th>Update</th>
              </tr>
            </thead>
            <tbody>{DoctorList}</tbody>
          </Table>{" "}
        </div>
      );
    }
  }
}

export default observer(DoctorList);
