import React, { Component } from "react";
import { Redirect } from "react-router";
import { observer } from "mobx-react";
import axios from "axios";
import Alert from "react-bootstrap/Alert";
import AuthStore from "../Stores/authStore";
import RoomStore from "../Stores/roomStore";
class Login extends Component {
  constructor(props) {
    super(props);

    this.state = {
      username: "",
      password: "",
      errorMessage: ""
    };

    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.getErrors = this.getErrors.bind(this);
  }

  componentDidUpdate() {
    if (AuthStore.user) return <Redirect to="/" />;
  }

  handleChange(event) {
    this.setState({ [event.target.name]: event.target.value });
  }

  getErrors() {
    if (AuthStore.errors) {
      let errors = Object.keys(AuthStore.errors);

      return AuthStore.errors[errors[0]];
    }
  }

  handleSubmit = async event => {
    event.preventDefault();
    await AuthStore.loginUser(this.state, this.props.history);
  };

  render() {
    const { username, password } = this.state;
    let errorMessage;
    if (AuthStore.errors) {
      let errors = Object.keys(AuthStore.errors);
      errorMessage = AuthStore.errors[errors[0]];
    }
    return (
      <div className="col-6 mx-auto">
        {errorMessage ? <Alert variant="danger">{errorMessage}</Alert> : null}
        <div className="card my-5">
          <div className="card-body">
            <form>
              <div className="form-group">
                <label htmlFor="username">Username</label>
                <input
                  type="text"
                  className="form-control"
                  id="username"
                  name="username"
                  value={username}
                  placeholder="Enter Username"
                  onChange={this.handleChange}
                />
              </div>
              <div className="form-group">
                <label htmlFor="password">Password</label>
                <input
                  type="password"
                  className="form-control"
                  id="password"
                  placeholder="Enter Password"
                  value={password}
                  name="password"
                  onChange={this.handleChange}
                />
              </div>

              <button
                onClick={this.handleSubmit}
                type="submit"
                className="btn btn-dark btn-block"
              >
                Login
              </button>
            </form>
          </div>
        </div>
      </div>
    );
  }
}

export default observer(Login);
