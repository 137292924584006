import React, { Component } from "react";
import RoomStore from "../../Stores/roomStore";
import UnitStore from "../../Stores/unitStore";
import RoomItem from "./RoomItem";

import Button from "react-bootstrap/Button";
import { observer } from "mobx-react";

class UnitItem extends Component {
  constructor(props) {
    super(props);
    this.state = {
      roomlist: []
    };

    this.handleClick = this.handleClick.bind(this);
  }

  handleClick(selected) {
    let room = this.state.roomlist.find(room => room.id === selected.id);
    if (room) {
      let roomlist = this.state.roomlist;
      roomlist = roomlist.filter(room => room.id !== selected.id);
      this.setState({ roomlist: roomlist });
    } else {
      this.setState(prevState => ({
        roomlist: [...prevState.roomlist, selected]
      }));
    }
  }

  getRooms(unit) {
    let RoomList;
    let rooms = unit.room;
    if (rooms) {
      RoomList = rooms.map(room => <RoomItem room={room} key={room.id} />);
    }
    return RoomList;
  }

  listAllRooms(unit) {
    let AllRoomList;
    let UnitRoomList;
    let rooms = RoomStore.rooms;
    if (rooms) {
      AllRoomList = rooms.map(room => (
        <RoomItem
          unitrooms={unit.room}
          room={room}
          select={() => this.handleClick(room)}
          key={room.id}
        />
      ));
    }

    return AllRoomList;
  }

  getRoomIds(roomlist) {
    let roomIDList = [];
    roomlist.forEach(room => {
      roomIDList.push(room.id);
    });
    return roomIDList;
  }

  updateUnits(unit) {
    let newRoomList = {};
    newRoomList["room"] = this.getRoomIds(this.state.roomlist);
    UnitStore.updateUnits(newRoomList, unit.id);
    UnitStore.fetchUnits();
  }
  render() {
    const storerooms = RoomStore.rooms;

    const { unit } = this.props;

    let doctorlist = unit.doctor.filter(
      doctor => doctor.user.is_active != false
    );
    let DoctorList;
    DoctorList = doctorlist.map(doctor => (
      <p key={doctor.id}>
        {" "}
        {doctor.user.first_name} {doctor.user.last_name}
      </p>
    ));
    return (
      <tr>
        <td>{unit.name}</td>
        <td>
          <div className="btn-group" role="group" aria-label="Basic example">
            {this.listAllRooms(unit)}
          </div>
        </td>
        <td>{DoctorList}</td>
        <td>
          <Button
            variant="dark"
            onClick={() => {
              this.updateUnits(unit);
            }}
          >
            Update
          </Button>
        </td>
      </tr>
    );
  }
}

export default observer(UnitItem);
