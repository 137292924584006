import React, { Component } from "react";
import { observer } from "mobx-react";
import UnitStore from "../../Stores/unitStore";
import UserStore from "../../Stores/userStore";
import UnitItem from "./UnitItem";

import Table from "react-bootstrap/Table";
class UnitList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      name: "",
      room: [],
      fetch: false
    };
    this.handleChange = this.handleChange.bind(this);
  }
  componentDidMount() {
    UnitStore.fetchUnits();
  }

  handleChange(event) {
    this.setState({ [event.target.name]: event.target.value });
  }

  getUnits() {
    this.setState({ fetch: !this.state.fetch });
  }
  render() {
    const { name, room } = this.state;
    const units = UnitStore.units;

    let UnitList;
    if (units) {
      UnitList = units.map(unit => (
        <UnitItem fetch={() => this.getUnits()} unit={unit} key={unit.id} />
      ));
    }

    return (
      <div>
        <div>
          <input
            type="text"
            name="name"
            value={name}
            placeholder="Unit Name"
            onChange={this.handleChange}
          />
          <button
            onClick={() => {
              if (name) {
                UnitStore.addUnit(this.state);
              } else {
                alert("ENTER UNIT NAME");
              }
            }}
          >
            Add Unit
          </button>
          <Table>
            <thead>
              <tr>
                <th>Unit Name</th>
                <th>Available Rooms</th>
                <th>Doctors</th>
                <th>Update </th>
              </tr>
            </thead>
            <tbody>{UnitList}</tbody>
          </Table>{" "}
        </div>

        <table>
          <thead />
        </table>
      </div>
    );
  }
}

export default observer(UnitList);
