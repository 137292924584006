import React, { Component } from "react";
import { Redirect } from "react-router-dom";
import { observer } from "mobx-react";
import Modal from "react-modal";

import RoomStore from "../../Stores/roomStore";
import AuthStore from "../../Stores/authStore";
import RoomItem from "./RoomItem";
import NoAuth from "../NoAuth";
// import OperationForm from "../../Forms/OperationForm";

class RoomList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      number: ""
    };
    this.getRooms();
    this.handleChange = this.handleChange.bind(this);
  }
  getRooms() {
    RoomStore.fetchRooms();
  }

  handleChange(event) {
    this.setState({ [event.target.name]: event.target.value });
  }

  render() {
    const { number } = this.state;
    const rooms = RoomStore.rooms;
    let RoomList;
    if (rooms) {
      RoomList = rooms.map(room => <RoomItem room={room} key={room.id} />);
    }

    return (
      <div className="text-center">
        {AuthStore.doctor && AuthStore.doctor.user.is_supseruser ? (
          <div>
            <input
              type="text"
              name="number"
              value={number}
              placeholder="Room Name"
              onChange={this.handleChange}
            />
            <button
              style={{ marginBottom: 20 }}
              onClick={() => {
                if (number) {
                  RoomStore.addRoom(this.state);
                } else {
                  alert("ENTER ROOM NUMBER");
                }
              }}
            >
              Add Room
            </button>
          </div>
        ) : null}
        <div className="card-deck"> {RoomList}</div>
      </div>
    );
  }
}

export default observer(RoomList);
