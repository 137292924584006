import React, { Component } from "react";
import { NavLink, Redirect, Link } from "react-router-dom";
import { observer } from "mobx-react";
import { withRouter } from "react-router";
import AuthStore from "../../Stores/authStore";
import UserStore from "../../Stores/userStore";
import "font-awesome/css/font-awesome.min.css";

class SideNavbar extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isOpen: false
    };
  }

  handleToggle = () => {
    this.setState({
      isOpen: !this.state.isOpen
    });
  };

  render() {
    const show = this.state.isOpen ? "show" : "";

    return (
      <nav
        style={{ marginBottom: 10 }}
        className="navbar navbar-expand-md navbar-dark bg-danger "
      >
        <span className="navbar-text">OT</span>
        <button onClick={this.handleToggle} className="navbar-toggler light">
          <span className="navbar-dark navbar-toggler-icon" />
        </button>
        <div className={"collapse navbar-collapse " + show}>
          {AuthStore.user ? (
            <ul className="navbar-nav navbar-sidenav">
              <li className="nav-item">
                <Link className="nav-link" to="/multi/room/">
                  Home
                </Link>
              </li>
              <li className="nav-item">
                <Link className="nav-link" to="/room/list/">
                  Rooms
                </Link>
              </li>
              <li className="nav-item">
                <Link className="nav-link" to="/tasks/">
                  Tasks
                </Link>
              </li>
            </ul>
          ) : null}
          {AuthStore.doctor && AuthStore.doctor.user.is_superuser ? (
            <ul className="navbar-nav">
              <li className="nav-item">
                <Link className="nav-link" to="/unit/list/">
                  Units
                </Link>
              </li>
              <li className="nav-item">
                <Link className="nav-link" to="/doctor/list/">
                  Doctors
                </Link>
              </li>
              <li className="nav-item">
                <Link className="nav-link" to="/user/list/">
                  Users
                </Link>
              </li>
            </ul>
          ) : null}

          {!AuthStore.user ? (
            <ul className="navbar-nav ml-auto">
              <li className="nav-item">
                <Link className="nav-link" to="/signup/">
                  Register
                </Link>
              </li>
              <li className="nav-item">
                <Link className="nav-link" to="/login/">
                  Login
                </Link>
              </li>
            </ul>
          ) : (
            <ul className="navbar-nav ml-auto">
              <li className="nav-item">
                <a
                  className="nav-link"
                  onClick={() => AuthStore.logout(this.props.history)}
                >
                  Logout
                </a>
              </li>
            </ul>
          )}
        </div>
      </nav>
    );
  }
}

export default withRouter(observer(SideNavbar));
