import React, { Component } from "react";
import { observer } from "mobx-react";
import BigCalendar from "react-big-calendar";
import moment from "moment";

import RoomStore from "../../Stores/roomStore";
import OperationPopup1 from "./OperationPopup";

const localizer = BigCalendar.momentLocalizer(moment);

class MultiRoomCalendar extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showPopup: false,
      showPopup1: false,
      selectedEvent: ""
    };

    this.handleEventSelect = this.handleEventSelect.bind(this);
  }

  componentDidMount() {
    RoomStore.fetchRooms();
  }

  handleEventSelect(event) {
    this.setState({ selectedEvent: event });
  }

  getOperationItemView() {
    if (this.state.selectedEvent) {
      return (
        <OperationPopup1
          room={this.props.room}
          operation={this.state.selectedEvent}
        />
      );
    }
  }

  render() {
    const rooms = RoomStore.rooms;

    let resourceMap1 = [];
    let events1 = [];
    rooms.forEach(room => {
      resourceMap1.push({ resourceId: room.id, resourceTitle: room.number });
      room.operation.forEach(operation => {
        let start = new Date(
          operation.admission_date + " " + operation.start_time
        );
        let end = new Date(
          operation.admission_date + " " + operation.start_time
        );
        end.setHours(end.getHours() + operation.duration);
        events1.push({
          id: operation.id,
          title:
            operation.patient.first_name +
            " " +
            operation.patient.last_name +
            "- " +
            operation.procedure.name,
          start: start,
          end: end,
          patient: operation.patient,
          doctor: operation.doctor,
          diagnosis: operation.diagnosis,
          duration: operation.duration,
          start_time: operation.start_time,
          admission_date: operation.admission_date,
          unit: operation.unit,
          procedure: operation.procedure.name,
          taskchecklist: operation.taskchecklist,
          resourceId: room.id
        });
      });
    });
    const show =
      this.state.selectedEvent === "" ? "col-lg-12" : "col-lg-10 col-sm-12";
    return (
      <div className="row">
        <div className={show}>
          <BigCalendar
            style={{ height: 700 }}
            localizer={localizer}
            defaultView={BigCalendar.Views.DAY}
            views={["day", "work_week"]}
            onSelectEvent={this.handleEventSelect}
            events={events1}
            defaultDate={new Date()}
            resources={resourceMap1}
            resourceIdAccessor="resourceId"
            resourceTitleAccessor="resourceTitle"
          />
        </div>
        <div className="col-2">{this.getOperationItemView()}</div>
      </div>
    );
  }
}

export default observer(MultiRoomCalendar);
