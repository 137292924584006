import React, { Component } from "react";

import OperationForm from "../../Forms/OperationForm";

import "./styles.css";

class OperationPopup extends Component {
  render() {
    return (
      <div className="popup">
        <div className="popup_inner">
          <OperationForm key={this.props.room.id} room={this.props.room} />
          <button onClick={this.props.closePopup}>close me</button>
        </div>
      </div>
    );
  }
}

export default OperationPopup;
