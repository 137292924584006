import React, { Component } from "react";

import PatientForm from "../Forms/PatientForm";
import Modal from "react-responsive-modal";
import { Button } from "reactstrap";

class PatientModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      open: false
    };

    this.onOpenModal = this.onOpenModal.bind(this);
    this.onCloseModal = this.onCloseModal.bind(this);
  }

  onOpenModal() {
    this.setState({ open: true });
  }

  onCloseModal() {
    this.setState({ open: false });
  }

  render() {
    const { open } = this.state;
    return (
      <div className="row">
        <div className="col-12">
          <Button
            style={{ backgroundColor: "#2b3c4f" }}
            onClick={this.onOpenModal}
            size="lg"
            block
          >
            Add an Patient
          </Button>
        </div>
        <div className="col-8">
          <Modal open={open} onClose={this.onCloseModal} center>
            <PatientForm closeModal={this.onCloseModal} />
          </Modal>
        </div>
      </div>
    );
  }
}

export default PatientModal;
