import React, { Component } from "react";
import { observer } from "mobx-react";
import OperationUpdateModal from "../OperationUpdateModal";
import RoomStore from "../../Stores/roomStore";
import AuthStore from "../../Stores/authStore";
import "../OperationPopup/styles.css";

import TaskList from "../TaskList/TaskList.js";

import unitStore from "../../Stores/unitStore";
class OperationPopup extends Component {
  componentDidMount() {
    unitStore.fetchAnOperation(this.props.operation.id);
  }
  getDoctorNames(operation) {
    let DoctorList;
    if (operation) {
      DoctorList = operation.doctor.map(doctor => (
        <p key={doctor.id} className="card-text" style={{ marginLeft: 10 }}>
          {doctor.user.first_name} {doctor.user.last_name}
        </p>
      ));
    }
    return DoctorList;
  }

  render() {
    const { operation } = this.props;

    if (operation) {
      let DoctorList = this.getDoctorNames(operation);
      return (
        <div>
          <div className="card">
            <div
              className="card-title center"
              style={{
                borderBottom: "1px solid rgb(199,206,213)",
                backgroundColor: "rgb(247,247,247)"
              }}
            >
              <h5 style={{ display: "inline" }}>Operation Information</h5>
              <a
                onClick={this.props.deselect}
                style={{
                  right: 10,
                  position: "absolute",
                  fontWeight: "bold"
                }}
              >
                X
              </a>
            </div>
            <p className="text-center">
              <strong>
                {operation.admission_date} {operation.start_time}
              </strong>
            </p>
            <p>
              <strong>Procedure:</strong> {operation.procedure.name}
            </p>

            <h5 className="card-title">Doctor Information</h5>
            {DoctorList}
            <h5 className="card-title">Patient Information</h5>
            <p style={{ marginLeft: 10 }} className="card-text">
              <strong>Name:</strong> {operation.patient.first_name}{" "}
              {operation.patient.last_name}
            </p>
            <p style={{ marginLeft: 10 }}>
              <strong>Civil ID #:</strong> {operation.patient.civil_id}
            </p>
            <p className="card-text">
              <strong>Diagnosis:</strong> {operation.diagnosis}
            </p>
            <p className="card-text">
              <strong>Task Checklist:</strong>
            </p>
            <TaskList tasks={operation.taskchecklist} />

            {AuthStore.doctor ? (
              <div className="card-footer">
                <div className="row">
                  <div className="col-lg-6 col-md-12">
                    <OperationUpdateModal
                      operation={operation}
                      room={this.props.room}
                      reset={this.props.deselect}
                    />
                  </div>
                  <div className="col-lg-6 col-md-12">
                    <button
                      onClick={() => {
                        RoomStore.deleteOperation(
                          this.props.room.id,
                          operation.id
                        );
                        this.props.deselect();
                      }}
                      type="button"
                      className="btn btn-outline-danger"
                    >
                      Delete
                    </button>
                  </div>
                </div>
              </div>
            ) : null}
          </div>
        </div>
      );
    } else {
      return <div />;
    }
  }
}

export default observer(OperationPopup);
