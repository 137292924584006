import React, { Component } from "react";
import { observer } from "mobx-react";

import UserStore from "../../Stores/userStore";

class UserItem extends Component {
  constructor(props) {
    super(props);
    this.state = {
      user: null,
      is_active: true
    };
  }

  componentDidMount() {
    this.setState({
      user: this.props.user.id
    });
  }

  render() {
    const { user } = this.props;

    return (
      <tr>
        <td>{user.first_name}</td>
        <td>{user.last_name}</td>
        <td>
          <button
            className="btn btn-dark"
            onClick={() => {
              UserStore.makeActive(user.id, this.state);
            }}
          >
            Activate Account
          </button>
        </td>
      </tr>
    );
  }
}

export default observer(UserItem);
