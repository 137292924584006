import React, { Component } from "react";
import { observer } from "mobx-react";

import UserStore from "../../Stores/userStore";

import UserItem from "./UserItem";
import Table from "react-bootstrap/Table";
class UserList extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  componentDidMount() {
    UserStore.fetchInActiveUsers();
  }

  render() {
    // UserStore.fetchUsers();
    const users = UserStore.users;
    let UserList;
    if (users) {
      UserList = users.map(user => <UserItem user={user} key={user.id} />);
    }

    return (
      <div>
        {" "}
        <Table>
          <thead>
            <tr>
              <th>First Name</th>
              <th>Last Name</th>
              <th>Activate?</th>
            </tr>
          </thead>
          <tbody>{UserList}</tbody>
        </Table>{" "}
      </div>
    );
  }
}

export default observer(UserList);
