import { decorate, observable, computed } from "mobx";
import axios from "axios";

const instance = axios.create({
  // baseURL: "http://127.0.0.1:8000/"
  baseURL: "https://otapi.traumasurgerykw.com/"
});

class UserStore {
  constructor() {
    this.doctors = [];
    this.users = [];
    this.loading = true;

    this.fetchUsers();
  }

  fetchUsers() {
    instance
      .get("doctor/list/")
      .then(res => res.data)
      .then(doctor => {
        this.doctors = doctor;
      })
      .then(() => {
        this.loading = false;
      })
      .catch(error => {
        this.error = error;
      });
  }

  fetchInActiveUsers() {
    instance
      .get("user/list/")
      .then(res => res.data)
      .then(users => {
        this.users = users;
      })
      .catch(error => console.error(error));
  }

  getUser(id) {
    return this.doctors.find(user => +user.id === +id);
  }

  giveAccess(userID, userInput) {
    instance.put(`/doctor/${userID}/update/`, userInput).then(res => res.data);
  }

  makeActive(userID, userInput) {
    instance
      .put(`/user/${userID}/active/`, userInput)
      .then(res => res.data)
      .then(() => this.fetchInActiveUsers());
  }

  get userlist() {
    if (this.users) {
      this.users.filter(user => user.is_active === false);
    }
  }
}

decorate(UserStore, {
  doctors: observable,
  users: observable,
  loading: observable,
  userlist: computed
});

export default new UserStore();
