import React, { Component } from "react";

import Button from "react-bootstrap/Button";
class RoomItem extends Component {
  constructor(props) {
    super(props);
    this.state = {
      colorOn: "outline-dark",
      clicked: false
    };
    this.handleOn = this.handleOn.bind(this);
  }

  componentDidMount() {
    this.getRoomButton(this.props.room);
  }
  handleOn(event) {
    if (this.state.clicked === false) {
      this.setState({
        colorOn: "dark",
        clicked: true
      });
    }

    if (this.state.clicked === true) {
      this.setState({
        colorOn: "outline-dark",
        clicked: false
      });
    }
  }

  getRoomButton(room) {
    if (this.props.unitrooms) {
      let existingRoom = this.props.unitrooms.find(
        unitroom => unitroom.id === room.id
      );
      if (existingRoom) {
        this.props.select(existingRoom);
        this.setState({
          colorOn: "dark",
          clicked: true
        });
      }
    }
  }

  render() {
    const { room } = this.props;

    return (
      <Button
        variant={this.state.colorOn}
        onClick={() => {
          this.props.select();
          this.handleOn();
        }}
      >
        {room.number}
      </Button>
    );
  }
}

export default RoomItem;
