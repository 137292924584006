import React, { Component } from "react";

import unitStore from "../../Stores/unitStore";
class TaskItem extends Component {
  constructor(props) {
    super(props);
    this.state = {
      completed: this.props.task.completed
    };
  }

  handleChange = async () => {
    await this.setState({ completed: !this.state.completed });
    unitStore.updateTask(this.state, this.props.task.id);
  };
  render() {
    const { task } = this.props;

    return (
      <div>
        <input
          style={{ marginRight: 10 }}
          type="checkbox"
          checked={this.state.completed}
          onChange={this.handleChange}
        />
        {task.task.name}
      </div>
    );
  }
}

export default TaskItem;
