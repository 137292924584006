import React, { Component } from "react";
import WeekCalendar from "react-week-calendar";
import { observer } from "mobx-react";
import axios from "axios";
import roomStore from "../../Stores/roomStore";
import OperationItem from "./OperationItem";
import OperationModal from "../OperationModal";
import PatientModal from "../PatientModal";
import OperationPopup from "../OperationPopup";

import AuthStore from "../../Stores/authStore";
import Dnd from "../DragAndDropCalendar";

import "react-week-calendar/dist/style.css";
import "./styles.css";
const instance = axios.create({
  baseURL: "http://127.0.0.1:8000/"
});

class RoomDetail extends Component {
  constructor(props) {
    super(props);
    this.state = {
      room: {},
      operations: [],
      units: [],
      loading: true,
      fetch: false
    };
    roomStore.getRoom(this.props.match.params.roomID);
  }

  componentDidMount() {
    roomStore.getRoom(this.props.match.params.roomID);
  }

  // getRoom() {
  //   const roomID = this.props.match.params.roomID;
  //   this.setState({ loading: true });
  //   instance
  //     .get(`room/detail/${roomID}/`)
  //     .then(res => res.data)
  //     .then(room => {
  //       this.setState({
  //         room: room,
  //         operations: room.operation,
  //         loading: false,
  //         units: room.unit
  //       });
  //     })
  //     .catch(err => console.error(err));
  // }

  test() {
    this.setState({ fetch: !this.state.fetch });
  }

  render() {
    const { room, operations, loading } = this.state;
    if (roomStore.loading) {
      return <div />;
    } else {
      let OperationList = [];
      if (operations) {
        OperationList = operations.map(operation => (
          <OperationItem operation={operation} key={operation.id} />
        ));
      }
      let events = [];
      if (operations) {
        operations.forEach(operation => {
          let start = new Date(
            operation.admission_date + " " + operation.start_time
          );
          let end = new Date(
            operation.admission_date + " " + operation.start_time
          );
          end.setHours(end.getHours() + operation.duration);
          events.push({
            id: operation.id,
            title: operation.diagnosis,
            start: start,
            end: end,
            patient: operation.patient,
            doctor: operation.doctor,
            diagnosis: operation.diagnosis,
            duration: operation.duration,
            start_time: operation.start_time,
            admission_date: operation.admission_date,
            unit: operation.unit,
            procedure: operation.procedure
          });
        });
      }

      return (
        <div className="row">
          <div className="col-12">
            <Dnd events={roomStore.roomOperations} room={roomStore.room} />
          </div>
        </div>
      );
    }
  }
}

export default observer(RoomDetail);
