import React, { Component } from "react";

import OperationForm from "../Forms/OperationForm";
import Modal from "react-responsive-modal";
import { Button } from "reactstrap";
import AuthStore from "../Stores/authStore";
class OperationUpdateModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      open: false
    };

    this.onOpenModal = this.onOpenModal.bind(this);
    this.onCloseModal = this.onCloseModal.bind(this);
  }

  onOpenModal() {
    this.setState({ open: true });
  }

  onCloseModal() {
    this.setState({ open: false });
  }

  render() {
    const { open } = this.state;

    const room = this.props.room;

    return (
      <div>
        <button
          style={{ marginLeft: 10 }}
          onClick={() => this.onOpenModal()}
          type="button"
          className="btn btn-outline-warning"
        >
          Update
        </button>
        <Modal open={open} onClose={this.onCloseModal} center>
          <OperationForm
            reset={this.props.reset}
            room={room}
            operation={this.props.operation}
            closeModal={this.onCloseModal}
          />
        </Modal>
      </div>
    );
  }
}

export default OperationUpdateModal;
