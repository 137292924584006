import React, { Component } from "react";
import { Link } from "react-router-dom";
import { Redirect } from "react-router";
import { observer } from "mobx-react";
import Alert from "react-bootstrap/Alert";
import AuthStore from "../Stores/authStore";
import UnitStore from "../Stores/unitStore";
class Signup extends Component {
  constructor(props) {
    super(props);

    this.state = {
      username: "",
      email: "",
      password: "",
      first_name: "",
      last_name: "",
      unit: "",
      errors: []
    };

    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  componentDidUpdate() {
    if (AuthStore.user) return <Redirect to="/" />;
  }

  handleChange(event) {
    this.setState({ [event.target.name]: event.target.value });
  }

  validate(username, email, password, first_name, last_name, unit) {
    const errors = [];
    if (username.length === 0) {
      errors.push("Username cannot be left blank");
    }
    if (first_name.length === 0 || last_name.length === 0) {
      errors.push("Name cannot be left blank");
    }
    if (
      email.split("").filter(x => x === "@").length !== 1 &&
      email.indexOf(".") === -1
    ) {
      errors.push("Please enter a valid Email");
    }
    if (password.length < 6) {
      errors.push("Passwords should be atleast 6 characters");
    }
    if (unit.length === 0) {
      errors.push("Please select a unit");
    }
    return errors;
  }

  handleUnitChange(e) {}

  componentDidUpdate() {
    if (AuthStore.user) return <Redirect to="/" />;
  }

  handleSubmit(event) {
    event.preventDefault();
    const {
      username,
      email,
      password,
      first_name,
      last_name,
      unit
    } = this.state;
    const errors = this.validate(
      username,
      email,
      password,
      first_name,
      last_name,
      unit
    );

    if (errors.length > 0) {
      this.setState({ errors });
      return;
    }
    AuthStore.signupUser(this.state, this.props.history);
  }

  getUnitDropdown() {
    const units = UnitStore.units;
    let UnitList;
    UnitList = units.map(unit => (
      <option key={unit.id} value={unit.id}>
        {unit.name}
      </option>
    ));
    return UnitList;
  }

  render() {
    const {
      username,
      email,
      password,
      first_name,
      last_name,
      unit,
      errors
    } = this.state;

    let errorMessage;
    if (AuthStore.errors) {
      let serverError = Object.keys(AuthStore.errors);
      errorMessage = "Username is taken. Try again.";
    }
    let MainError;
    if (errorMessage) {
      MainError = <Alert variant="danger">{errorMessage}</Alert>;
    } else if (!errorMessage && errors.length > 0) {
      MainError = <Alert variant="danger">{errors[0]}</Alert>;
    }
    return (
      <div>
        <div className="col-6 mx-auto">
          {MainError}
          <div className="card my-5">
            <div className="card-body">
              <form onSubmit={this.handleSubmit}>
                <div className="form-group">
                  <label htmlFor="username">Username</label>
                  <input
                    type="text"
                    className="form-control"
                    id="username"
                    value={username}
                    name="username"
                    placeholder="Username"
                    onChange={this.handleChange}
                  />
                </div>
                <div className="form-group">
                  <label htmlFor="first_name">First Name</label>

                  <input
                    type="text"
                    className="form-control"
                    id="first_name"
                    value={first_name}
                    name="first_name"
                    placeholder="first_name"
                    onChange={this.handleChange}
                  />
                </div>
                <div className="form-group">
                  <label htmlFor="last_name">Last Name</label>
                  <input
                    type="text"
                    className="form-control"
                    id="last_name"
                    value={last_name}
                    name="last_name"
                    placeholder="last_name"
                    onChange={this.handleChange}
                  />
                </div>
                <div className="form-group">
                  <label htmlFor="email">Email</label>
                  <input
                    type="email"
                    className="form-control"
                    id="email"
                    value={email}
                    name="email"
                    placeholder="Email"
                    onChange={this.handleChange}
                  />
                </div>
                <div className="form-group">
                  <label htmlFor="password">Password</label>
                  <input
                    type="password"
                    className="form-control"
                    id="password"
                    value={password}
                    name="password"
                    placeholder="Password"
                    onChange={this.handleChange}
                  />
                </div>

                <div className="form-group">
                  <label htmlFor="unit">Unit</label>
                  <div>
                    <select
                      style={{
                        backgroundColor: "transparent",
                        position: "relative",
                        width: "100%",
                        height: 35,
                        marginBottom: 10
                      }}
                      defaultValue={this.state.unit}
                      name="unit"
                      id="unit"
                      onChange={this.handleChange}
                    >
                      <option />
                      {this.getUnitDropdown()}
                    </select>
                  </div>
                </div>
                <button type="submit" className="btn btn-dark btn-block">
                  Signup
                </button>
                <div className="text-center ">
                  <Link to="/login" className="btn btn-link my-2 my-sm-0">
                    I already have an account
                  </Link>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default observer(Signup);
