import React, { Component } from "react";
import { NavLink } from "react-router-dom";

import "./style.css";

import authStore from "../../Stores/authStore";
class Sidebar extends Component {
  render() {
    return (
      <div id="sidebar">
        <section>
          {authStore.user ? (
            <div>
              <h4 className="menu-item active">
                <NavLink to="/room/list">Rooms</NavLink>
              </h4>
              <h4 className="menu-item">
                <NavLink to="/unit/list/">Unit</NavLink>
              </h4>
              <h4 className="menu-item">
                <NavLink to="/operations/form/">Doctors</NavLink>
              </h4>
              <h4 className="menu-item">
                <NavLink to="/user/list/">Users</NavLink>
              </h4>
            </div>
          ) : null}

          {!authStore.user ? (
            <div>
              <h4 className="menu-item">
                <NavLink to="/signup">SIGNUP</NavLink>
              </h4>
              <h4 className="menu-item">
                <NavLink to="/login">LOGIN</NavLink>
              </h4>
            </div>
          ) : (
            <h4 className="menu-item">
              <button onClick={() => authStore.logout(this.props.history)}>
                LOGOUT
              </button>
            </h4>
          )}
        </section>
      </div>
    );
  }
}

export default Sidebar;
