import React, { Component } from "react";
import OperationUpdateModal from "../OperationUpdateModal";
import RoomStore from "../../Stores/roomStore";

import TaskList from "../TaskList/TaskList.js";
import "../OperationPopup/styles.css";

class OperationPopup extends Component {
  getDoctorNames(operation) {
    let DoctorList;

    DoctorList = operation.doctor.map(doctor => (
      <p key={doctor.id} className="card-text" style={{ marginLeft: 10 }}>
        {doctor.user.first_name} {doctor.user.last_name}
      </p>
    ));

    return DoctorList;
  }

  render() {
    const { operation } = this.props;
    let DoctorList = this.getDoctorNames(operation);

    if (operation) {
      return (
        <div>
          <div className="card">
            <h5 className="card-title">Operation Information</h5>

            <h5 className="card-title">Doctor Information</h5>
            {DoctorList}
            <h5 className="card-title">Patient Information</h5>
            <p style={{ marginLeft: 10 }} className="card-text">
              Name: {operation.patient.first_name} {operation.patient.last_name}
            </p>
            <p style={{ marginLeft: 10 }}>
              Civil ID #: {operation.patient.civil_id}
            </p>
            <p className="card-text">Diagnosis: {operation.diagnosis}</p>
            <p className="card-text">
              <strong>Task Checklist:</strong>
            </p>
            <TaskList tasks={operation.taskchecklist} />
          </div>
        </div>
      );
    } else {
      return <div />;
    }
  }
}

export default OperationPopup;
