import React, { Component } from "react";

import { observer } from "mobx-react";
import LoginForm from "../../Forms/LoginForm";
import AuthStore from "../../Stores/authStore";

class Login extends Component {
  constructor(props) {
    super(props);
    this.state = {
      error: ""
    };
  }

  render() {
    return (
      <div>
        <LoginForm history={this.props.history} />
      </div>
    );
  }
}
export default observer(Login);
